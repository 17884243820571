import React, { Component, Fragment } from 'react';
import Features from '../Hometwo/Features';
import Framework from './Framework';
import Post from './Post';
import Testimonials from './Testimonials';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Features/>
                <Framework/>
                <Testimonials/>
                <Post/>
            </Fragment>
        );
    }
}

export default Content;