import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';

// Image
import img1 from '../../../assets/img/portfolio/17.jpg';
import img2 from '../../../assets/img/portfolio/18.jpg';
import img3 from '../../../assets/img/portfolio/22.jpg';
import img4 from '../../../assets/img/portfolio/19.jpg';
import img5 from '../../../assets/img/portfolio/20.jpg';
import img6 from '../../../assets/img/portfolio/21.jpg';

const filtersbtn = [
    { name: "All Works", value: "All Works" },
    { name: "It Solution", value: "It Solution" },
    { name: "Consultancy", value: "Consultancy" },
    { name: "UX Design", value: "UX Design" },
    { name: "Web Design", value: "Web Design" },
    { name: "Seo", value: "Seo" }
];
class Portfoliocontent extends Component {
    constructor() {
        super();
        this.state = {
            strains: [{
                    photo: img1,
                    origin: "It Solution",
                    name: "mixer website solution",
                    cat: "uix design",
                },
                {
                    photo: img2,
                    origin: "Consultancy",
                    name: "mixer website solution",
                    cat: "uix design",
                },
                {
                    photo: img4,
                    origin: "UX Design",
                    name: "mixer website solution",
                    cat: "uix design",
                },
                {
                    photo: img3,
                    origin: "Web Design",
                    name: "mixer website solution",
                    cat: "uix design",
                    cls: "col-lg-8 col-sm-12",
                    clsinr: "double-height",
                },
                {
                    photo: img5,
                    origin: "Seo",
                    name: "mixer website solution",
                    cat: "uix design",
                },
                {
                    photo: img6,
                    origin: "It Solution",
                    name: "mixer website solution",
                    cat: "uix design",
                }
            ],
            filterStrain: []
        };
    }
    componentDidMount() {
        this.setState({
            filterStrain: this.state.strains
        });
    }
    handleClick = name => {
        let filterStrain = [];
        if (name === "All Works") {
            filterStrain = this.state.strains;
        } else {
            filterStrain = this.state.strains.filter(
                (strain, i) => strain.origin === name
            );
        }

        this.setState({ filterStrain });
    };
    render() {
        const imagesLoadedOptions = {
            itemSelector: '.masonry-item',
            percentPosition: false,
            gutter: 30,
            resize: true,
            fitWidth: true
        };
        const renderAll = this.state.filterStrain.map((portfolio, i) => (
            <div key={i} className={"masonry-item col-lg-4 col-sm-6 " + portfolio.cls +""}>
                <div className={"portfolio-box " + portfolio.clsinr +""}>
                    <div className="portfolio-img" style={{ backgroundImage: "url(" + portfolio.photo + ")" }} />
                    <div className="portfolio-desc">
                        <span className="portfolio-cat">{portfolio.cat}</span>
                        <h4><Link to="/portfolio-detail">{portfolio.name}</Link></h4>
                    </div>
                </div>
            </div>
        ));
        return (
            <section className="portfolio-section pt-120 pb-90">
                <div className="container">
                    {/* portfolio filter */}
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="portfolio-filter masonry-filter">
                                <ul>
                                    {filtersbtn.map(({ name, value }) => (
                                        <li key={name} value={value} onClick={this.handleClick.bind(this, name)}>{name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* portfolio loop */}
                    <Masonry className="row portfolio-masonary-loop masonry-items" imagesLoadedOptions={imagesLoadedOptions}>
                        {renderAll}
                    </Masonry>
                </div>
            </section>
        );
    }
}

export default Portfoliocontent;