import React, { Component, Fragment } from 'react';
import About from './About';
import Banner from './Banner';
import Blog from './Blog';
import Cta from './Cta';
import Features from './Features';
import Portfolio from './Portfolio';
import Services from './Services';
import Skills from './Skills';
import Team from './Team';
import Testimonials from './Testimonials';
import Video from './Video';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Banner/>
                <About/>
                <Services/>
                <Cta/>
                <Features/>
                <Video/>
                <Team/>
                <Skills/>
                <Portfolio/>
                <Testimonials/>
                <Blog/>
            </Fragment>
        );
    }
}

export default Content;