import React, { Component, Fragment } from 'react';
import About from './About';
import Banner from './Banner';
import Cta from './Cta';
import Featured from './Featured';
import Portfolio from './Portfolio';
import Services from './Services';
import Team from './Team';
import Video from './Video';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Banner/>
                <Featured/>
                <About/>
                <Services/>
                <Portfolio/>
                <Cta/>
                <Team/>
                <Video/>
                <Content/>
            </Fragment>
        );
    }
}

export default Content;