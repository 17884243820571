import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Image
import img1 from '../../../assets/img/about/about-mock.png';
import img2 from '../../../assets/img/about/about.jpg';

// abouticonic
const abouticonic = [
    {
        title: "Machine Learning",
        text: "Get regular support from our team",
    },
    {
        title: "Artificial Intelligence",
        text: "Get regular support from our team",
    },
    {
        title: "Augmented Reality",
        text: "Get regular support from our team",
    },
]

class About extends Component {
    render() {
        return (
            <section className="about-section about-style-two">
                <div className="about-iconic-boxes">
                    <div className="container">
                        <div className="row justify-content-center">
                            {abouticonic.map((item, i) => (
                                <div key={i} className="col-lg-4 col-md-6 col-sm-8">
                                    <div className="iconic-box">
                                        <h4>{item.title}</h4>
                                        <p>{item.text}</p>
                                        <span className="icon"><i className="fal fa-check" /></span>
                                        <span className="count">0{i}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* About Text */}
                <div className="about-text-warp">
                    <div className="container">
                        <div className="row no-gutters justify-content-center justify-content-lg-start">
                            <div className="col-lg-5 col-md-10">
                                <div className="about-text position-relative">
                                    <div className="section-title left-border mb-40">
                                        <span className="title-tag">About us</span>
                                        <h2>Onitir help businesses elevate their value.</h2>
                                    </div>
                                    <p className="mb-30">Over the years, a wide range of developments and innovations in the global
                                    IT
                                    arena have led to many new IT-enabled devices and services being produced. Moreover,
                                    there
              is need for IT today, not just in urban areas but rural regions as well.</p>
                                    <p>Entrepreneurs, artist, athletes and other individuals who have excelled in their field
                                    share
                                    fun and inspiring stories from their journey to success. Individuals who have excelled
                                    in
              their field share.</p>
                                    <Link to="/about" className="main-btn btn-filled mt-40">Get Started Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mockup-img">
                        <img src={img1} alt="" />
                    </div>
                    <div className="about-small-img">
                        <img src={img2} alt="" />
                    </div>
                </div>
            </section>

        );
    }
}

export default About;