import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Headsidebar from './Offcanvas';
import Navigation from './Navigation';
import classNames from 'classnames'

import logo from '../../assets/img/logo.png';

class Headertwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classmethod: false,
            togglemethod: false,
        };
        this.addClass = this.addClass.bind(this);
        this.removeClass = this.removeClass.bind(this);
        this.toggleClass = this.toggleClass.bind(this);
    }
    addClass() {
        this.setState({
            classmethod: true
        });
    }

    removeClass() {
        this.setState({
            classmethod: false
        });
    }
    toggleClass() {
        this.setState({
            togglemethod: !this.state.togglemethod
        });
    }
    // Sticky header
    componentDidMount() {
        window.addEventListener('scroll', () => {
            this.setState({
                isTop: window.scrollY > 110
            });
        }, false);
    }
    render() {
        const stickyheader = this.state.isTop ? 'sticky' : '';
        return (
            <header className={`header-two sticky-header ${stickyheader}`}>
                <div className="header-top-area">
                    <div className="container-fluid custom-container-two">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-sm-7">
                                <ul className="contact-list">
                                    <li><Link to="#">info@webmail.com</Link></li>
                                    <li><Link to="#">+988 987 876 87 67 6</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-sm-5">
                                <ul className="social-link">
                                    <li><Link to="#">fb</Link></li>
                                    <li><Link to="#">tw</Link></li>
                                    <li><Link to="#">be</Link></li>
                                    <li><Link to="#">yu</Link></li>
                                    <li><Link to="#">li</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mainmenu-area">
                    <div className="container-fluid custom-container-two">
                        <div className="d-flex align-items-center justify-content-between">
                            <nav className={classNames("main-menu", { "active": this.state.togglemethod })}>
                                <div className="logo">
                                    <Link to="/"><img src={logo} alt="Onitit" /></Link>
                                </div>
                                <Navigation />
                            </nav >
                            <div className="main-right">
                                <div className="quote-icon">
                                    <img src="assets/img/clock-icon.png" className="icon" alt="Clock" />
                                    <span>
                                        Get A Quote
              <span>988. 876 76 76 8</span>
                                    </span>
                                </div>
                                <Link to="#" className="offcanvas-trigger" onClick={this.addClass}>
                                    <i className="far fa-ellipsis-v" />
                                </Link>
                                {/* OFF CANVAS WRAP START */}
                                <div className={classNames("off-canvas-wrap", { "show-off-canvas": this.state.classmethod })}>
                                    <div className={classNames("overly", { "show-overly": this.state.classmethod })} onClick={this.removeClass} />
                                    <div className="off-canvas-widget">
                                        <Link to="#" className="off-canvas-close" onClick={this.removeClass}><i className="fal fa-times" /></Link>
                                        <Headsidebar />
                                    </div>
                                </div>
                                {/* OFF CANVAS WRAP END */}
                            </div>
                            <div className={classNames("mobilehamburger", { "active": this.state.togglemethod })} onClick={this.toggleClass}>
                                <div>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </header >
        );
    }
}

export default Headertwo;