import React, { Component } from 'react';
import { Link } from 'react-router-dom';


const navigationmenu = [
    {
        id: 1,
        linkText: 'Home',
        child: true,
        submenu: [
            {
                id: 11,
                link: '/',
                linkText: 'Home One'
            },
            {
                id: 12,
                link: '/homepage-2',
                linkText: 'Home Two'
            },
            {
                id: 13,
                link: '/homepage-3',
                linkText: 'Home Three'
            },
        ]
    },
    {
        id:2,
        linkText:'About',
        link: '/about'
    },
    {
        id:3,
        linkText:'Services',
        child:true,
        submenu:[
            {
                id: 31,
                link: '/services',
                linkText: 'Services'
            },
            {
                id: 32,
                link: '/service-detail',
                linkText: 'Service Details'
            },
        ]
    },
    {
        id:4,
        linkText:'Pages',
        child:true,
        submenu:[
            {
                id: 41,
                link: '/why-choose-us',
                linkText: 'Why Choose Us'
            },
            {
                id: 42,
                linkText: 'Team',
                child:true,
                submenu:[
                    {
                        id:421,
                        linkText: 'Team',
                        link: '/team'
                    },
                    {
                        id:422,
                        linkText: 'Team Details',
                        link: '/team-detail'
                    },
                ]
            },
            {
                id: 43,
                linkText: 'Portfolio',
                child:true,
                submenu:[
                    {
                        id:431,
                        linkText: 'Portfolio Grid',
                        link: '/portfolio-grid'
                    },
                    {
                        id:432,
                        linkText: 'Portfolio Masonary',
                        link: '/portfolio-masonary'
                    },
                    {
                        id:433,
                        linkText: 'Portfolio Slider',
                        link: '/portfolio-slider'
                    },
                    {
                        id:434,
                        linkText: 'Portfolio Details',
                        link: '/portfolio-detail'
                    },
                ]
            },
            {
                id: 44,
                linkText: 'News',
                child:true,
                submenu:[
                    {
                        id:441,
                        linkText: 'News Standard',
                        link: '/blog'
                    },
                    {
                        id:442,
                        linkText: 'News Grid',
                        link: '/blog-grid'
                    },
                    {
                        id:443,
                        linkText: 'News Details',
                        link: '/blog-detail'
                    },
                ]
            },
            {
                id: 45,
                link: '/faqs',
                linkText: 'FAQ'
            },
        ]
    },
    {
        id: 5,
        linkText: 'News',
        child:true,
        submenu:[
            {
                id:51,
                linkText: 'News Standard',
                link: '/blog'
            },
            {
                id:52,
                linkText: 'News Grid',
                link: '/blog-grid'
            },
            {
                id:53,
                linkText: 'News Details',
                link: '/blog-detail'
            },
        ]
    },
    {
        id:6,
        linkText:'Contact',
        link: '/contact-us'
    },
]
class Navigation extends Component {
    getNextSibling = function (elem, selector) {
        // Get the next sibling element
        var sibling = elem.nextElementSibling;
        // If there's no selector, return the first sibling
        if (!selector) return sibling;
        // If the sibling matches our selector, use it
        // If not, jump to the next sibling and continue the loop
        while (sibling) {
            if (sibling.matches(selector)) return sibling;
            sibling = sibling.nextElementSibling
        }
    }
    triggerChild = (e) => {
        let subMenu = '';
        subMenu = (this.getNextSibling(e.target, '.submenu') !== undefined) ? this.getNextSibling(e.target, '.submenu') : null;
        if (subMenu !== null && subMenu !== undefined && subMenu !== '') {
            subMenu.classList = subMenu.classList.contains('open') ? 'submenu' : 'submenu open';
        }
    }
    render() {
        return (
            <div className="menu-items">
                <ul>
                    {navigationmenu.length > 0 ? navigationmenu.map((item, i) => (
                        <li key={i} className={`${item.child ? 'has-menu' : ''} `} onClick={this.triggerChild}>
                            {item.child ? <Link onClick={e => e.preventDefault()} to="/"> {item.linkText} </Link> : <Link to={item.link}> {item.linkText} </Link>}
                            {item.child ?
                                <ul className="submenu" role="menu">
                                    {item.submenu.map((sub_item, i) => (
                                        <li key={i} className={`${sub_item.child ? 'has-menu' : ''} `}>
                                            {sub_item.child ? <Link onClick={e => e.preventDefault()} to="/"> {sub_item.linkText} </Link> : <Link to={sub_item.link}> {sub_item.linkText} </Link>}
                                            {sub_item.submenu ?
                                                <ul className="submenu">
                                                    {sub_item.submenu.map((third_item, i) => (
                                                        <li key={i}><Link
                                                            to={third_item.link}>{third_item.linkText}</Link>
                                                        </li>
                                                    ))}
                                                </ul> : null}
                                        </li>
                                    ))}
                                </ul>
                                : null
                            }
                        </li>
                    )) : null}
                </ul >
            </div >
        );
    }
}

export default Navigation;