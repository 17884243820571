import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Slider from 'react-slick';

// Image
import img1 from '../../../assets/img/team/01.jpg';
import img2 from '../../../assets/img/team/02.jpg';
import img3 from '../../../assets/img/team/03.jpg';
import img4 from '../../../assets/img/team/04.jpg';
import img5 from '../../../assets/img/team/05.jpg';

const teampost = [
    {
        img: img1,
        name: "Rosa D.William",
        designation: "Founder & CEO",
    },
    {
        img: img2,
        name: "hilixer b. browni",
        designation: "co-founder",
    },
    {
        img: img3,
        name: "pokoloko k. kilix",
        designation: "consultant",
    },
    {
        img: img4,
        name: "pokoloko k. kilix",
        designation: "consultant",
    },
    {
        img: img5,
        name: "pokoloko k. kilix",
        designation: "consultant",
    },
    {
        img: img1,
        name: "Rosa D.William",
        designation: "Founder & CEO",
    },
    {
        img: img2,
        name: "hilixer b. browni",
        designation: "co-founder",
    },
    {
        img: img3,
        name: "pokoloko k. kilix",
        designation: "consultant",
    },
    {
        img: img4,
        name: "pokoloko k. kilix",
        designation: "consultant",
    },
]

class Team extends Component {
    render() {
        const settings = {
            infinite: true,
            autoplay: false,
            arrows: false,
            dots: false,
            slidesToShow: 5,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };
        return (
            <section className="team-section has-slider pt-120 pb-120">
                <div className="container-fluid">
                    <Slider className="row team-loop team-slider-one" {...settings}>
                        {teampost.map((item, i) => (
                            <div key={i} className="col-lg-12">
                                <div className="member-box">
                                    <div className="member-img">
                                        <img src={item.img} alt="" />
                                    </div>
                                    <div className="member-info">
                                        <h3>{item.name}</h3>
                                        <span>{item.designation}</span>
                                    </div>
                                    <Link routerLink="#" className="socail-trigger">+</Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        );
    }
}

export default Team;