import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick'

const bannerpost = [
    {
        title:"We Create",
        titlespan:"Perfect",
        titlespanafter:"Brand Goods",
        titlespanafterspan:"Value",
        btntext1:"Get Started Now",
        btntext2:"Learn More",
    },
    {
        title:"We Create",
        titlespan:"Perfect",
        titlespanafter:"Brand Goods",
        titlespanafterspan:"Value",
        btntext1:"Get Started Now",
        btntext2:"Learn More",
    },
    {
        title:"We Create",
        titlespan:"Perfect",
        titlespanafter:"Brand Goods",
        titlespanafterspan:"Value",
        btntext1:"Get Started Now",
        btntext2:"Learn More",
    },
]

class Banner extends Component {
    render() {
        const settings = {
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: false,
            fade: true,
            arrows: true,
            prevArrow:
                '<span class="slick-arrow prev-arrow"><i class="fal fa-long-arrow-left"></i>Prev</span>',
            nextArrow:
                '<span class="slick-arrow next-arrow">next<i class="fal fa-long-arrow-right"></i></span>',
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                    },
                },
            ],
        }
        return (
            <section className="banner-section banner-style-three banner-slider-two" style={{ backgroundImage: 'url(assets/img/banner/02.jpg)' }}>
                <Slider className="slider-active" id="bannerSliderTwo" {...settings}>
                    {bannerpost.map((item, i) => (
                    <div key={i} className="single-banner">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="banner-text">
                                        <h1 data-animation="fadeInUp" data-delay=".7s" style={{ animationDelay: '0.7s' }}>{item.title}
                <span>{item.titlespan}</span> {item.titlespanafter} <span>{item.titlespanafterspan}</span></h1>
                                        <div className="btn-wrap" data-animation="fadeInUp" data-delay=".9s">
                                            <Link to="/contact-us" className="main-btn btn-filled">{item.btntext1}</Link>
                                            <Link to="/about" className="main-btn btn-borderd">{item.btntext2}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}
                    
                </Slider>
                <div className="banner-shape-three">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z">
                        </path>
                    </svg>
                </div>
            </section>
        );
    }
}

export default Banner;