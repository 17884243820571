import React, { Component, Fragment } from 'react';
import Services from './Services';
import Skills from './Skills';
import Teamblock from './Teamblock';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Teamblock/>
                <Services/>
                <Skills/>
            </Fragment>
        );
    }
}

export default Content;