import React, { Component, Fragment } from 'react';
import Detailtext from './Detailtext';
import Portfolio from './Portfolio';

class Content extends Component {
    render() {
        return ( 
            <Fragment>
                <Detailtext/>
                <Portfolio/>
            </Fragment>
        );
    }
}

export default Content;