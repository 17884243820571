import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';

import frameworkimg from '../../../assets/img/framework.jpg';

class Framework extends Component {
    render() {
        return (
            <section className="framework-section padding-bottom-extra">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="framework-img">
                                <img src={frameworkimg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10">
                            <div className="framework-text pl-20">
                                <div className="section-title left-border mb-40">
                                    <span className="title-tag">framework</span>
                                    <h2> we are using best quality framwork for you. </h2>
                                </div>
                                <Tab.Container defaultActiveKey="wordpress">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="phython">
                                            <p>
                                                Over the years, a wide range of developments and innovations in the global IT arena
                                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                                is need for IT today, not just in urban areas but rural regions as well.
                                            </p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="wordpress">
                                            <p>
                                                Over the years, a wide range of developments and innovations in the global IT arena
                                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                                is need for IT today, not just in urban areas but rural regions as well.
                                            </p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="html">
                                            <p>
                                                Over the years, a wide range of developments and innovations in the global IT arena
                                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                                is need for IT today, not just in urban areas but rural regions as well.
                                            </p>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="java">
                                            <p>
                                                Over the years, a wide range of developments and innovations in the global IT arena
                                                have led to many new IT-enabled devices and services being produced. Moreover, there
                                                is need for IT today, not just in urban areas but rural regions as well.
                                            </p>
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <Nav variant="pills" className="framework-list nav nav-pills mt-25">
                                        <Nav.Item>
                                            <Nav.Link eventKey="phython">
                                                <span className="icon">
                                                    <i className="fab fa-python" />
                                                </span>
                                                Python
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="wordpress">
                                                <span className="icon">
                                                    <i className="fab fa-wordpress" />
                                                </span>
                                                Wordpress
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="html">
                                                <span className="icon">
                                                    <i className="fab fa-html5" />
                                                </span>
                                                HTML5
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="java">
                                                <span className="icon">
                                                    <i className="fab fa-java" />
                                                </span>
                                                JAVA
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Framework;