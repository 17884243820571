import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../layouts/Blogsidebar';

const blogpost = [
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "Business",
        title: "Consider we have two screens of the ui app, the first screen has instructions.",
        text: "Too much data and forcing the brain to accommodate all these data frustrate the user and leads them to abandon the task. The short term memory of our brain has.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-7",
    },
    {
        category: "SAAS",
        title: "All these data frustrate the user and leads.",
        text: "Too much data and forcing the brain to accommodate all these data fru strate the user and leads them.",
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        colclass: "col-lg-5",
    },
];

const url = "/blog-detail";

class Content extends Component {
    render() {
        return (
            <section className="blog-section pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        {/* Blog Loop Start */}
                        <div className="col-lg-8 col-md-8 col-sm-10">
                            <div className="blog-loop">
                                <div className="row justify-content-center">
                                    {blogpost.map((item, i) => (
                                        <div key={i} className={item.colclass}>
                                            <div className="post-grid-box mb-30">
                                                <ul className="post-cat">
                                                    <li><Link to={url}>{item.category}</Link></li>
                                                </ul>
                                                <div className="post-desc">
                                                    <h4><Link to={url}>{item.title}</Link>
                                                    </h4>
                                                    <p>{item.text}</p>
                                                </div>
                                                <ul className="post-meta">
                                                    <li><Link to={url}><i className="fal fa-calendar-alt" />{item.postdate}</Link></li>
                                                    <li><Link to={url}><i className="fal fa-comments" />{item.postcomment}</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* Blog Sidebar */}
                        <div className="col-lg-4 col-md-8 col-sm-10">
                            <Sidebar />
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Content;