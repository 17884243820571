import React from 'react';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export default function Counter() {
    const [focus, setFocus] = React.useState(false);

    return (
        <section className="counter-section mt-negative">
            <div className="container">
                <div className="counter-inner">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="counter-box">
                                <CountUp start={focus ? 0 : null} end={299} duration={5} redraw={true}>
                                    {({ countUpRef }) => (
                                        <h1>
                                            <span className="counter" ref={countUpRef} />
                                            <VisibilitySensor
                                                onChange={isVisible => {
                                                    if (isVisible) {
                                                        setFocus(true);
                                                    }
                                                }}
                                            >
                                                <span>+</span>
                                            </VisibilitySensor>
                                        </h1>
                                    )}
                                </CountUp>
                                <p className="title">Project Done</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="counter-box">
                                <CountUp start={focus ? 0 : null} end={277} duration={5} redraw={true}>
                                    {({ countUpRef }) => (
                                        <h1>
                                            <span className="counter" ref={countUpRef} />
                                            <VisibilitySensor
                                                onChange={isVisible => {
                                                    if (isVisible) {
                                                        setFocus(true);
                                                    }
                                                }}
                                            >
                                                <span>+</span>
                                            </VisibilitySensor>
                                        </h1>
                                    )}
                                </CountUp>
                                <p className="title">Active Client</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="counter-box">
                                <CountUp start={focus ? 0 : null} end={5} duration={5} redraw={true}>
                                    {({ countUpRef }) => (
                                        <h1>
                                            <span className="counter" ref={countUpRef} />
                                            <VisibilitySensor
                                                onChange={isVisible => {
                                                    if (isVisible) {
                                                        setFocus(true);
                                                    }
                                                }}
                                            >
                                                <span>K+</span>
                                            </VisibilitySensor>
                                        </h1>
                                    )}
                                </CountUp>
                                <p className="title">Drink Coffee</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="counter-box">
                                <CountUp start={focus ? 0 : null} end={69} duration={5} redraw={true}>
                                    {({ countUpRef }) => (
                                        <h1>
                                            <span className="counter" ref={countUpRef} />
                                            <VisibilitySensor
                                                onChange={isVisible => {
                                                    if (isVisible) {
                                                        setFocus(true);
                                                    }
                                                }}
                                            >
                                                <span>K+</span>
                                            </VisibilitySensor>
                                        </h1>
                                    )}
                                </CountUp>
                                <p className="title">Award winning</p>
                            </div>
                        </div>
                    </div>
                    <span className="big-text">
                        Onitir
                        </span>
                </div>
            </div>
        </section>
    );

}
