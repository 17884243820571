import React, { Component } from 'react';
import {Link} from 'react-router-dom';

// Icons
import icon1 from '../../../assets/img/services/icon-1.png';
import icon2 from '../../../assets/img/services/icon-2.png';
import icon3 from '../../../assets/img/services/icon-3.png';

const services = [
    {
        icon:icon1,
        title:"Date Research Analysis",
        text:"Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.",
        parentclass:"single-service text-center white-bg",
    },
    {
        icon:icon2,
        title:"Date Research Analysis",
        text:"Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.",
        parentclass:"single-service text-center secondary-bg doted mt-60",
    },
    {
        icon:icon3,
        title:"Maintainance & Data sent",
        text:"Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.",
        parentclass:"single-service text-center primary-bg mt-120",
    },
]
class Services extends Component {
    render() {
        return (
            <section className="services-secton pt-120 pb-200">
                <div className="container">
                    <div className="section-title mb-80 text-center both-border">
                        <span className="title-tag">Services</span>
                        <h2>What We Do</h2>
                    </div>
                    <div className="services-loop">
                        <div className="row justify-content-center">
                            {services.map((item, i) => (
                            <div key={i} className="col-lg-4 col-md-6 col-sm-10">
                                <div className={item.parentclass}>
                                    <div className="icon">
                                        <img src={item.icon} alt="Icon" />
                                    </div>
                                    <h4>{item.title}</h4>
                                    <p>{item.text}</p>
                                    <Link to="/service-detail" className="service-link">Read More</Link>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Services;