import React, { Component, Fragment } from 'react';
import Cta from '../Portfoliogrid/Cta';
import Sliderblock from './Sliderblock';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Sliderblock/>
                <Cta/>
            </Fragment>
        );
    }
}

export default Content;