import React, { Component } from 'react';
import { Link } from 'react-router-dom'

// Images
import advimg from '../../assets/img/banner-widget.jpg';
import author from '../../assets/img/author.png';
// Feed img
import feedimg1 from '../../assets/img/recent-post-wid/04.png';
import feedimg2 from '../../assets/img/recent-post-wid/05.png';
import feedimg3 from '../../assets/img/recent-post-wid/06.png';

// Feed post
const feedposts = [
    {
        photo: feedimg1,
        title: "Lorem ipsum dolor sit cing elit, sed do.",
        date: "24th March 2020",
    },
    {
        photo: feedimg2,
        title: "Lorem ipsum dolor sit cing elit, sed do.",
        date: "24th March 2020",
    },
    {
        photo: feedimg3,
        title: "Lorem ipsum dolor sit cing elit, sed do.",
        date: "24th March 2020",
    },
];

// Categories
const categories = [
    {
        catname: "Business",
        catcount: "26",
    },
    {
        catname: "Consultant",
        catcount: "26",
    },
    {
        catname: "Creative",
        catcount: "26",
    },
    {
        catname: "UI/UX",
        catcount: "26",
    },
    {
        catname: "Technology",
        catcount: "26",
    },
];

// Twitter feeds
const twitterfeed = [
    { feed: "Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @ ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1", date: "November 25, 2018" },
    { feed: "Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @ ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1", date: "November 25, 2018" },
    { feed: "Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @ ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1", date: "November 25, 2018" }
];

// Insta feeds
const instafeed = [
    { img: "assets/img/instagram/01.jpg" },
    { img: "assets/img/instagram/02.jpg" },
    { img: "assets/img/instagram/03.jpg" },
    { img: "assets/img/instagram/04.jpg" },
    { img: "assets/img/instagram/05.jpg" },
    { img: "assets/img/instagram/06.jpg" },
    { img: "assets/img/instagram/07.jpg" },
    { img: "assets/img/instagram/08.jpg" },
    { img: "assets/img/instagram/09.jpg" }
];

// Tags
const tags = [
    { tag: "Popular" },
    { tag: "design" },
    { tag: "ux" },
    { tag: "usability" },
    { tag: "develop" },
    { tag: "icon" },
    { tag: "business" },
    { tag: "consult" },
    { tag: "kit" },
    { tag: "keyboard" },
    { tag: "mouse" },
    { tag: "tech" }
]

class Blogsidebar extends Component {
    render() {
        return (
            <div className="sidebar">
                {/* About Author Widget */}
                <div className="widget about-author-widget mb-40">
                    <h5 className="widget-title">About Me</h5>
                    <div className="author-box">
                        <img src={author} alt="author" />
                        <h6>Rosalina D. Willaimson</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        <ul className="social-icon">
                            <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                            <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                            <li><Link to="#"><i className="fab fa-behance" /></Link></li>
                            <li><Link to="#"><i className="fab fa-linkedin" /></Link></li>
                            <li><Link to="#"><i className="fab fa-youtube" /></Link></li>
                        </ul>
                    </div>
                </div>
                {/* Search Widget */}
                <div className="widget search-widget mb-40">
                    <h5 className="widget-title">Search Objects</h5>
                    <form action="#">
                        <input type="text" placeholder="Search your keyword..." />
                        <button type="submit"><i className="far fa-search" /></button>
                    </form>
                </div>
                {/* Popular Post Widget */}
                <div className="widget popular-feeds mb-40">
                    <h5 className="widget-title">Popular Feeds</h5>
                    <div className="popular-feed-loop">
                        {feedposts.map((item, i) => (
                            <div key={i} className="single-popular-feed">
                                <div className="feed-img">
                                    <img src={item.photo} alt="" />
                                </div>
                                <div className="feed-desc">
                                    <h6><Link to="/blog-detail">{item.title}</Link></h6>
                                    <span className="time"><i className="far fa-calendar-alt" /> {item.date}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Categories Widget */}
                <div className="widget categories-widget mb-40">
                    <h5 className="widget-title">Categories</h5>
                    <ul>
                        {categories.map((item, i) => (
                            <li key={i}>
                                <Link to="/blog-detail">{item.catname}<span>{item.catcount}</span></Link>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* Social Icon Widget */}
                <div className="widget socail-widget mb-40">
                    <h5 className="widget-title">Never Miss News</h5>
                    <ul>
                        <li><Link to="#"><i className="fab fa-facebook-f" /></Link></li>
                        <li><Link to="#"><i className="fab fa-twitter" /></Link></li>
                        <li><Link to="#"><i className="fab fa-behance" /></Link></li>
                        <li><Link to="#"><i className="fab fa-linkedin" /></Link></li>
                        <li><Link to="#"><i className="fab fa-pinterest" /></Link></li>
                    </ul>
                </div>
                {/* Twitter Feeds Widget */}
                <div className="widget twitter-feed-widget mb-40">
                    <h5 className="widget-title">Twitter Feeds</h5>
                    <ul>
                        {twitterfeed.map((item, i) => (
                            <li key={i}>
                                <Link to="/blog-detail">
                                    {item.feed}
                                </Link>
                                <span className="date">{item.date}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* Instagram Feeds Widget */}
                <div className="widget instagram-feed-widget mb-40">
                    <h5 className="widget-title">Instagram Feeds</h5>
                    <ul>
                        {instafeed.map((item, i) => (
                            <li key={i}><img src={item.img} alt="" /></li>
                        ))}
                    </ul>
                </div>
                {/* Popular Tags Widget */}
                <div className="widget popular-tag-widget mb-40">
                    <h5 className="widget-title">Popular Tags</h5>
                    <ul>
                        {tags.map((item, i) => (
                            <li key={i}><Link to="/blog-detail">{item.tag}</Link></li>
                        ))}
                    </ul>
                </div>
                {/* Banner Ad Widget */}
                <div className="widget banner-ad-widget">
                    <img src={advimg} alt="" />
                </div>
            </div>
        );
    }
}

export default Blogsidebar;