import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Image
import img1 from '../../../assets/img/portfolio/06.jpg';
import img2 from '../../../assets/img/portfolio/01.jpg';
import img3 from '../../../assets/img/portfolio/07.jpg';
import img4 from '../../../assets/img/portfolio/08.jpg';
import img5 from '../../../assets/img/portfolio/10.jpg';
import img6 from '../../../assets/img/portfolio/11.jpg';
import img7 from '../../../assets/img/portfolio/12.jpg';
import img8 from '../../../assets/img/portfolio/13.jpg';
import img9 from '../../../assets/img/portfolio/14.jpg';

const filtersbtn = [
    { name: "All Works", value: "All Works" },
    { name: "It Solution", value: "It Solution" },
    { name: "Consultancy", value: "Consultancy" },
    { name: "UX Design", value: "UX Design" },
    { name: "Web Design", value: "Web Design" },
    { name: "Seo", value: "Seo" }
];
class Portfolioblock extends Component {
    constructor() {
        super();
        this.state = {
            strains: [{
                photo: img1,
                origin: "It Solution",
            },
            {
                photo: img2,
                origin: "Consultancy",
            },
            {
                photo: img3,
                origin: "UX Design",
            },
            {
                photo: img4,
                origin: "Web Design",
            },
            {
                photo: img5,
                origin: "Seo",
            },
            {
                photo: img6,
                origin: "UX Design",
            },
            {
                photo: img7,
                origin: "Seo",
            },
            {
                photo: img8,
                origin: "Consultancy",
            },
            {
                photo: img9,
                origin: "It Solution",
            },
            ],
            filterStrain: []
        };
    }

    componentDidMount() {
        this.setState({
            filterStrain: this.state.strains
        });
    }
    handleClick = name => {
        let filterStrain = [];
        if (name === "All Works") {
            filterStrain = this.state.strains;
        } else {
            filterStrain = this.state.strains.filter(
                (strain, i) => strain.origin === name
            );
        }

        this.setState({ filterStrain });
    };
    render() {
        const renderAll = this.state.filterStrain.map((strain, i) => (
            <div key={i} className="col-lg-4 col-sm-6">
                <div className="portfolio-grid-box">
                    <div className="portfolio-img" style={{ backgroundImage: "url(" + strain.photo + ")" }} />
                    <Link to={strain.photo} className="portfolio-link">
                        <i className="far fa-expand" />
                    </Link>
                </div>
            </div>
        ));
        return (
            <section className="portfolio-section pt-120 pb-90">
                <div className="container">
                    {/* portfolio filter */}
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="portfolio-filter grid-filter">
                                <ul>
                                    {filtersbtn.map(({ name, value }) => (
                                        <li key={name} value={value} onClick={this.handleClick.bind(this, name)}>{name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* portfolio loop */}
                    <div className="row portfolio-grid-loop grid-isotope">
                        {renderAll}
                    </div>
                </div>
            </section>
        );
    }
}

export default Portfolioblock;