import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

// Client Image
import img1 from '../../../assets/img/brand/01.png';
import img2 from '../../../assets/img/brand/02.png';
import img3 from '../../../assets/img/brand/03.png';
import img4 from '../../../assets/img/brand/04.png';
import img5 from '../../../assets/img/brand/05.png';

// Testimonial image
import quote from '../../../assets/img/testimonials/quote.png';
import tesimg1 from '../../../assets/img/testimonials/01.jpg';
import tesimg2 from '../../../assets/img/testimonials/02.jpg';
import tesimg3 from '../../../assets/img/testimonials/03.jpg';


// Client loop
const clientloop = [
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
];

// Testimonial post
const testimonialpost = [
    {
        img: tesimg1,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
    {
        img: tesimg2,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
    {
        img: tesimg3,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
    {
        img: tesimg1,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
    {
        img: tesimg2,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
    {
        img: tesimg3,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
]
class Testimonials extends Component {
    render() {
        const settings = {
            infinite: true,
            autoplay: false,
            arrows: false,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        };
        const clientslider = {
            infinite: true,
            autoplay: false,
            arrows: false,
            dots: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        }
        return (
            <section className="testimonials-clinet-section pt-120 pb-120">
                <div className="container">
                    <div className="section-title both-border text-center pb-80">
                        <span className="title-tag">Testimonials</span>
                        <h2>Client Feedbacks</h2>
                    </div>
                    <div className="testimonials-section pb-125">
                        <Slider className="testimonials-slider-two row" {...settings}>
                            {testimonialpost.map((item, i) => (
                                <div key={i} className="col-lg-12">
                                    <div className="single-testimonial-two">
                                        <p>{item.text}</p>
                                        <div className="testimonial-author">
                                            <div className="author-img">
                                                <img src={item.img} alt="Img" />
                                            </div>
                                            <div className="author-info">
                                                <h5>{item.name}</h5>
                                                <span>{item.designation}</span>
                                            </div>
                                        </div>
                                        <span className="quote-icon">
                                            <img src={quote} alt="Icon" />
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                <div className="clinet-section">
                    <div className="container-fluid custom-container-one">
                        <Slider className="clinet-slider" {...clientslider}>
                            {clientloop.map((item, i) => (
                                <div key={i} className="clinet-item">
                                    <Link to="#">
                                        <img src={item.img} alt="" />
                                    </Link>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        );
    }
}

export default Testimonials;