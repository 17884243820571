import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Blog extends Component {
    render() {
        return (
            <section className="latetest-post pt-120 pb-90">
                <div className="container">
                    {/* Section  Title */}
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-8 col-sm-7 col-6">
                            <div className="section-title left-border">
                                <span className="title-tag">Blog</span>
                                <h2>news feeds</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-5 col-6">
                            <div className="blog-btn text-right">
                                <Link to="/blog" className="main-btn btn-filled">learn more</Link>
                            </div>
                        </div>
                    </div>
                    {/* post Loop */}
                    <div className="post-loop mt-70">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-md-12 col-sm-10 order-2 order-lg-1">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="post-grid-box mb-30">
                                            <ul className="post-cat">
                                                <li><Link to="/blog-detail">Software</Link></li>
                                            </ul>
                                            <div className="post-desc">
                                                <h4><Link to="/blog-detail">All these data frustrate the user and leads.</Link>
                                                </h4>
                                                <p>Too much data and forcing the brain to accommodate all these data fru strate
                                                the user
                    and leads them.</p>
                                            </div>
                                            <ul className="post-meta">
                                                <li><Link to="/blog-detail"><i className="fal fa-calendar-alt" />14th May 2020</Link></li>
                                                <li><Link to="/blog-detail"><i className="fal fa-comments" />10 Comments</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="post-grid-box mb-30">
                                            <ul className="post-cat">
                                                <li><Link to="/blog-detail">SAAS</Link></li>
                                            </ul>
                                            <div className="post-desc">
                                                <h4><Link to="/blog-detail">All these data frustrate the user and leads.</Link>
                                                </h4>
                                                <p>Too much data and forcing the brain to accommodate all these data fru strate
                                                the user
                    and leads them.</p>
                                            </div>
                                            <ul className="post-meta">
                                                <li><Link to="/blog-detail"><i className="fal fa-calendar-alt" />14th May 2020</Link></li>
                                                <li><Link to="/blog-detail"><i className="fal fa-comments" />10 Comments</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-10 col-sm-10 order-1 order-lg-2">
                                <div className="post-grid-box mb-30">
                                    <ul className="post-cat">
                                        <li><Link to="/blog-detail">Business</Link></li>
                                    </ul>
                                    <div className="post-desc">
                                        <h4><Link to="/blog-detail">Consider we have two screens of the ui app, the first
                  screen has instructions.</Link></h4>
                                        <p>Too much data and forcing the brain to accommodate all these data frustrate the user
                                        and leads them to abandon the task. The short term memory of our brain has
                limitations.</p>
                                    </div>
                                    <ul className="post-meta">
                                        <li><Link to="/blog-detail"><i className="fal fa-calendar-alt" />14th May 2020</Link></li>
                                        <li><Link to="/blog-detail"><i className="fal fa-comments" />10 Comments</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Blog;