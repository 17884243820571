import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Imgae
import img1 from '../../../assets/img/services/icon-1.png';
import img2 from '../../../assets/img/services/icon-2.png';
import img3 from '../../../assets/img/services/icon-3.png';

const featuredpost = [
    {
        extraclass: "white-bg mt-60",
        icon: img1,
        title: "Date Research Analysis",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.",
        linktext: "Read More",
    },
    {
        extraclass: "secondary-bg doted mt-60",
        icon: img2,
        title: "Date Research Analysis",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.",
        linktext: "Read More",
    },
    {
        extraclass: "primary-bg mt-60",
        icon: img3,
        title: "Maintainance & Data sent",
        text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.",
        linktext: "Read More",
    },
]

class Featured extends Component {
    render() {
        return (
            <section className="services-secton featured-service mt-negative">
                <div className="container">
                    <div className="services-loop">
                        <div className="row justify-content-center">
                            {featuredpost.map((item, i) => (
                                <div key={i} className="col-lg-4 col-md-6 col-sm-10">
                                    <div className={"single-service text-center " + item.extraclass + ""}>
                                        <div className="icon">
                                            <img src={item.icon} alt="Icon" />
                                        </div>
                                        <h4>{item.title}</h4>
                                        <p>{item.text}</p>
                                        <Link to="/service-detail" className="service-link">{item.linktext}</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Featured;