import React, { Component } from 'react';
import { Link } from 'react-router-dom'

// Image
import img1 from '../../../assets/img/laest-post/01.jpg';
import img2 from '../../../assets/img/laest-post/02.jpg';
import img3 from '../../../assets/img/laest-post/03.jpg';

// Blog posts 
const blogposts = [
    {
        img: img1,
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        title: "We’ll then ask you to tell us your current level",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        linktext: "Read More",
    },
    {
        img: img2,
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        title: "We’ll then ask you to tell us your current level",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        linktext: "Read More",
    },
    {
        img: img3,
        postdate: "14th May 2020",
        postcomment: "10 Comments",
        title: "We’ll then ask you to tell us your current level",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.",
        linktext: "Read More",
    },
]

class Blog extends Component {
    render() {
        return (
            <section className="latetest-post pt-120 pb-90">
                <div className="container">
                    <div className="section-title text-center both-border mb-80">
                        <span className="title-tag">Blog</span>
                        <h2>news feeds</h2>
                    </div>
                    {/* Blog Loop */}
                    <div className="row justify-content-center">
                        {blogposts.map((item, i) => (
                            <div key={i} className="col-lg-4 col-md-6 col-sm-10">
                                <div className="latest-post-box mb-30 text-center">
                                    <ul className="post-meta">
                                        <li><Link to="#"><i className="fal fa-calendar-alt" />{item.postdate}</Link></li>
                                        <li><Link to="#"><i className="fal fa-comments" />{item.postcomment}</Link></li>
                                    </ul>
                                    <div className="post-img mb" style={{ backgroundImage: "url("+ item.img +")" }} />
                                    <div className="post-desc mt-35">
                                        <h3><Link to="/blog-detail">{item.title}</Link></h3>
                                        <p>{item.text}.</p>
                                        <Link to="/blog-detail" className="post-link"> {item.linktext} </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        );
    }
}

export default Blog;