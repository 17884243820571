import React, { Component } from 'react';
import {Link} from 'react-router-dom'

// image
import img1 from '../../../assets/img/portfolio/01.jpg';
import img2 from '../../../assets/img/portfolio/02.jpg';
import img3 from '../../../assets/img/portfolio/03.jpg';
import img4 from '../../../assets/img/portfolio/04.jpg';
import img5 from '../../../assets/img/portfolio/05.jpg';

const portfoliopost = [
    {
        img:img1,
        title:"uix design",
        text:"mixer website solution",
    },
    {
        img:img2,
        title:"uix design",
        text:"mixer website solution",
    },
    {
        img:img3,
        title:"uix design",
        text:"mixer website solution",
    },
    {
        img:img4,
        title:"uix design",
        text:"mixer website solution",
    },
    {
        img:img5,
        title:"uix design",
        text:"mixer website solution",
        expandcol:"col-lg-8 col-sm-12"
    },
]

class Portfolio extends Component {
    render() {
        return (
            <section className="portfolio-section pt-120 pb-90">
                <div className="container">
                    <div className="section-title text-center both-border mb-80">
                        <span className="title-tag">portfolio</span>
                        <h2>case study</h2>
                    </div>
                    {/* portfolio loop */}
                    <div className="row portfolio-masonary-loop">
                        {portfoliopost.map((item, i) => (
                            <div key={i} className={"col-lg-4 col-sm-6 "+ item.expandcol +""}>
                                <div className="portfolio-box height-extra">
                                    <div className="portfolio-img" style={{ backgroundImage: "url("+ item.img +")" }} />
                                    <div className="portfolio-desc">
                                        <span className="portfolio-cat">{item.title}</span>
                                        <h4><Link to="portfolio-detail">{item.text}</Link></h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default Portfolio;