import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

// Image
import img1 from '../../../assets/img/portfolio/23.jpg';
import img2 from '../../../assets/img/portfolio/24.jpg';
import img3 from '../../../assets/img/portfolio/25.jpg';

// portfoliopost
const portfoliopost = [
    {
        img: img1,
        title: "Miranda grocery app design",
        category: "ui/ux design, mobile app",
    },
    {
        img: img2,
        title: "Miranda grocery app design",
        category: "ui/ux design, mobile app",
    },
    {
        img: img3,
        title: "Miranda grocery app design",
        category: "ui/ux design, mobile app",
    },
    {
        img: img1,
        title: "Miranda grocery app design",
        category: "ui/ux design, mobile app",
    },
    {
        img: img2,
        title: "Miranda grocery app design",
        category: "ui/ux design, mobile app",
    },
    {
        img: img3,
        title: "Miranda grocery app design",
        category: "ui/ux design, mobile app",
    },
]
class Portfolio extends Component {
    render() {
        const settings = {
            infinite: true,
            autoplay: false,
            arrows: false,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            speed: 900,
            centerPadding: '25%',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        centerPadding: '20%',
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        centerPadding: '10%',
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        centerPadding: '5%',
                    },
                },
                {
                    breakpoint: 400,
                    settings: {
                        centerPadding: '0',
                    },
                },
            ],
        }
        return (
            <section className="portfolio-slider-section pt-120 pb-120">
                <div className="container-fluid">
                    {/* portfolio loop */}
                    <Slider className="row portfolio-slider-loop portfolio-slider" {...settings}>
                        {portfoliopost.map((item, i) => (
                            <div key={i} className="col-12">
                                <div className="portfolio-box">
                                    <div className="portfolio-img-wrap">
                                        <div className="portfolio-img" style={{ backgroundImage: "url(" + item.img + ")" }}>
                                        </div>
                                    </div>
                                    <div className="portfolio-desc">
                                        <span className="portfolio-cat">{item.category}</span>
                                        <h2><Link to="/portfolio-detail">{item.title}</Link></h2>
                                        <Link to="/portfolio-detail" className="portfolio-link">
                                            <i className="fal fa-arrow-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        );
    }
}

export default Portfolio;