import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Preloader
import Preloader from './components/layouts/Preloader';
// Pages
import Home from './components/pages/Home';
import Hometwo from './components/pages/Hometwo';
import Homethree from './components/pages/Homethree';
import About from './components/pages/About';
import Bloggrid from './components/pages/Bloggrid';
import Blogclassic from './components/pages/Blogclassic';
import Blogdetail from './components/pages/Blogdetail';
import Portfoliogrid from './components/pages/Portfoliogrid';
import Portfoliomasonary from './components/pages/Portfoliomasonary';
import Portfolioslider from './components/pages/Portfolioslider';
import Portfoliodetail from './components/pages/Portfoliodetail';
import Services from './components/pages/Services';
import Servicedetail from './components/pages/Servicedetail';
import Team from './components/pages/Team';
import Teamdetail from './components/pages/Teamdetail';
import Whyus from './components/pages/Whyus';
import Faqs from './components/pages/Faqs';
import Contact from './components/pages/Contact';

function App() {
  return (
    <Router>
      <Preloader/>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/homepage-2" component={Hometwo} />
        <Route path="/homepage-3" component={Homethree} />
        <Route path="/about" component={About} />
        <Route path="/services" component={Services} />
        <Route path="/service-detail" component={Servicedetail} />
        <Route path="/why-choose-us" component={Whyus} />
        <Route path="/team" component={Team} />
        <Route path="/team-detail" component={Teamdetail} />
        <Route path="/portfolio-grid" component={Portfoliogrid} />
        <Route path="/portfolio-masonary" component={Portfoliomasonary} />
        <Route path="/portfolio-slider" component={Portfolioslider} />
        <Route path="/portfolio-detail" component={Portfoliodetail} />
        <Route path="/faqs" component={Faqs} />
        <Route path="/blog-grid" component={Bloggrid} />
        <Route path="/blog" component={Blogclassic} />
        <Route path="/blog-detail" component={Blogdetail} />
        <Route path="/contact-us" component={Contact} />
      </Switch>
    </Router>
  );
}

export default App;
