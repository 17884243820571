import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'

// Recent post
import img1 from '../../assets/img/laest-post/01.jpg';
import img2 from '../../assets/img/laest-post/02.jpg';
import img3 from '../../assets/img/laest-post/03.jpg';

const recentpost = [
    {
        photo: img1,
        title: "Signs moved moveth itself on years whose without.",
        date: "4 May.2020",
    },
    {
        photo: img2,
        title: "Under brought airte them saw stars created seas.",
        date: "4 May.2020",
    },
    {
        photo: img3,
        title: "Our form there seas set forth fill bearing.",
        date: "4 May.2020",
    },

]
class Offcanvas extends Component {
    render() {
        return (
            <Fragment>
                <div className="widget recent-post">
                    <h4 className="widget-title">Recent Posts</h4>
                    <ul>
                        {recentpost.map((item, i) => (
                            <li key={i}>
                                <div className="post-img" style={{ backgroundImage: "url("+ item.photo +")" }}>
                                </div>
                                <div className="post-content">
                                    <h6>
                                        <Link to="blog-detail">{item.title}</Link>
                                    </h6>
                                    <span className="time"><i className="far fa-clock" />{item.date}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="widget contact-widget">
                    <h4 className="widget-title">Contact Us</h4>
                    <ul>
                        <li>
                            <i className="far fa-map-marker-alt" />
                    305 Royal Track Suite 019, USA
                  </li>
                        <li>
                            <i className="far fa-phone" />
                            <Link to="#">+10910-910920</Link>
                        </li>
                        <li>
                            <i className="far fa-envelope-open" />
                            <Link to="#">support@onitir.com</Link>
                        </li>
                    </ul>
                </div>
                <div className="widget social-widget">
                    <h4 className="widget-title">Follow Us</h4>
                    <ul>
                        <li>
                            <Link to="#"><i className="fab fa-facebook-f" /></Link>
                            <Link to="#"><i className="fab fa-twitter" /></Link>
                            <Link to="#"><i className="fab fa-instagram" /></Link>
                            <Link to="#"><i className="fab fa-behance" /></Link>
                        </li>
                    </ul>
                </div>
            </Fragment>
        );
    }
}

export default Offcanvas;