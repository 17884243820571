import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Slider from 'react-slick';

import bannerimg from '../../../assets/img/banner/01.jpg';

// bannerposts
const bannerposts = [
    {
        title: "We Create Perfect Brand Goods Value",
        subtitle: "Bring your ideas to life with us",
        btntext1: "Get Started Now",
        btntext2: "Learn More",
    },
    {
        title: "We Create Perfect Brand Goods Value",
        subtitle: "Bring your ideas to life with us",
        btntext1: "Get Started Now",
        btntext2: "Learn More",
    },
    {
        title: "We Create Perfect Brand Goods Value",
        subtitle: "Bring your ideas to life with us",
        btntext1: "Get Started Now",
        btntext2: "Learn More",
    },
]

class Banner extends Component {
    render() {
        const settings = {
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: true,
            fade: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                    },
                },
            ],
        };
        return (
            <section className="banner-section" style={{ backgroundImage: "url(" + bannerimg + ")" }}>
                <Slider className="slider-active" id="bannerSliderOne" {...settings}>
                    {bannerposts.map((item, i) => (
                        <div key={i} className="single-banner">
                            <div className="container container-custom">
                                <div className="row extra-left">
                                    <div className="col-lg-8">
                                        <div className="banner-text">
                                            <h1 data-animation="fadeInUp" data-delay=".7s" style={{ animationDelay: '0.7s' }}>{item.title}</h1>
                                            <p data-animation="fadeInUp" data-delay=".9s">{item.subtitle}</p>
                                            <div className="btn-wrap" data-animation="fadeIn" data-delay="1.5s">
                                                <Link to="contact-us" className="main-btn btn-filled">{item.btntext1}</Link>
                                                <Link to="about" className="main-btn btn-borderd">{item.btntext2}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className="banner-shape-one" />
                <div className="banner-shape-two" />
            </section>
        );
    }
}

export default Banner;