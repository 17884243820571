import React, { Component, Fragment } from 'react';
import About from './About';
import Counter from './Counter';
import Portfolio from './Portfolio';
import Post from './Post';
import Services from './Services';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <About/>
                <Counter/>
                <Services/>
                <Portfolio/>
                <Post/>
            </Fragment>
        );
    }
}

export default Content;