import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Slider from 'react-slick';

// Image
import img1 from '../../../assets/img/test-author.png';

// top testimonial
const testimonialtop = [
    {
        img: img1,
        text: "Online proofing smart review allows pages within multi page documents to be treated as. Aproove was built to be content is real.",
    },
    {
        img: img1,
        text: "Online proofing smart review allows pages within multi page documents to be treated as. Aproove was built to be content is real.",
    },
    {
        img: img1,
        text: "Online proofing smart review allows pages within multi page documents to be treated as. Aproove was built to be content is real.",
    },
    {
        img: img1,
        text: "Online proofing smart review allows pages within multi page documents to be treated as. Aproove was built to be content is real.",
    },
    {
        img: img1,
        text: "Online proofing smart review allows pages within multi page documents to be treated as. Aproove was built to be content is real.",
    },
    {
        img: img1,
        text: "Online proofing smart review allows pages within multi page documents to be treated as. Aproove was built to be content is real.",
    },
];
// bottom testimonial
const testimonialbottom = [
    {
        name: "Rosalina D. William",
        designation: "Founder, Kilixer Co.",
    },
    {
        name: "Rosalina D. William",
        designation: "Founder, Kilixer Co.",
    },
    {
        name: "Rosalina D. William",
        designation: "Founder, Kilixer Co.",
    },
    {
        name: "Rosalina D. William",
        designation: "Founder, Kilixer Co.",
    },
    {
        name: "Rosalina D. William",
        designation: "Founder, Kilixer Co.",
    },
    {
        name: "Rosalina D. William",
        designation: "Founder, Kilixer Co.",
    },
]

class Testimonials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }
    render() {
        const settings = {
            autoplay: false,
            infinite: true,
            arrows: false,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        const settingsthumb = {
            autoplay: false,
            infinite: true,
            arrows: false,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        }
        return (
            <section className="testimonial-section pt-120 pb-120">
                <div className="container">
                    {/* testimonial Content Slider*/}
                    <Slider className="testimonial-slider mb-45" {...settings} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)}>
                        {testimonialtop.map((item, i) => (
                            <div key={i}>
                                <div className="single-testimonial">
                                    <div className="author-img">
                                        <img src={item.img} alt="Author" />
                                    </div>
                                    <div className="desc">
                                        <div className="rateing mb-20">
                                            <Link to="#"><i className="fas fa-star" /></Link>
                                            <Link to="#"><i className="fas fa-star" /></Link>
                                            <Link to="#"><i className="fas fa-star" /></Link>
                                            <Link to="#"><i className="fas fa-star" /></Link>
                                            <Link to="#"><i className="fal fa-star" /></Link>
                                        </div>
                                        <h2>“{item.text}”</h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                    {/* Author Info Slider */}
                    <div className="row no-gutters justify-content-center">
                        <div className="col-lg-9">
                            <Slider className="testimonial-author-slider" {...settingsthumb} asNavFor={this.state.nav1} ref={slider => (this.slider2 = slider)}>
                                {testimonialbottom.map((item, i) => (
                                    <div key={i} className="single-slider">
                                        <h4>{item.name}</h4>
                                        <span>{item.designation}</span>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Testimonials;